import { Component } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-table-skeleton-loader',
  standalone: true,
  imports: [NgxSkeletonLoaderModule],
  template: `
    <!-- <div class="grid grid-cols-5 gap-2">
      @for (loader of skeletonTotal; track loader.valueOf) {
        <ngx-skeleton-loader
          [theme]="{ height: '2rem', width: '100px' }"
        ></ngx-skeleton-loader>
      }
    </div> -->
    <div class="flex flex-col">
      @for (loader of skeletonTotal; track loader.valueOf) {
        <ngx-skeleton-loader
          [theme]="{ height: '3rem', width: '100%' }"
        ></ngx-skeleton-loader>
      }
    </div>
  `,
  styleUrl: './table-skeleton-loader.component.scss',
})
export class TableSkeletonLoaderComponent {
  skeletonTotal = Array.from(Array(10).keys());
}
